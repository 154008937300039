//Dependencias Internas
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import React from 'react'
import Banner from './banner/Banner'
import Blog from './blog/Blog'
import WhatsappButton from './buttonWhatsapp/WhatsappButton'
import Depoimentos from './depoimentos/Depoimentos'
import Diferenciais from './diferenciais/Diferenciais'
import Footer from './footer/Footer'
import Imprensa from './Imprensa/Imprensa'
import MaisVendidos from './mais-vendidos/MaisVendidos'
import Navbar from './navbar/Navbar'
import Newsletter from './newsletter/Newsletter'
import Personalizados from './produtos-personalizados/Personalizados'
import WelcomeTextos from './welcome-textos/WelcomeTextos'


export default props =>
    <div className="Home">
        <WhatsappButton />
        <Banner />
        <Navbar />
        <MaisVendidos />
        <WelcomeTextos />
        <Personalizados />
        <Imprensa />
        <Depoimentos />
        <Blog />
        <Diferenciais />
        <Newsletter />
        <Footer />
    </div>
