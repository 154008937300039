import { gql } from "@apollo/client";

export const GET_BANNERS_QUERY = gql`
  query GetBanners {
    banners {
      data {
        id
        attributes {
          imagem {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CATALOGO_QUERY = gql`
  query GetCatalogo {
    catalogo {
      data {
        attributes {
          arquivo {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const ADD_NEWSLETTER_EMAIL_MUTATION = gql`
  mutation SendEmail($email: String!) {
    createNewsletterEmail(data: { email: $email }) {
      data {
        attributes {
          email
        }
      }
    }
  }
`;

export const GET_BLOG_POSTS_QUERY = gql`
  query GetBlogPosts($limit: Int!) {
    blogPosts(pagination: { limit: $limit }) {
      data {
        id
        attributes {
          titulo
          imagem {
            data {
              attributes {
                url
              }
            }
          }
          conteudo
        }
      }
    }
  }
`;

export const GET_BLOG_POST_BY_ID_QUERY = gql`
  query GetBlogPostsById($id: ID!) {
    blogPost(id: $id) {
      data {
        id
        attributes {
          titulo
          imagem {
            data {
              attributes {
                url
              }
            }
          }
          conteudo
        }
      }
    }
  }
`;

export const GET_BLOG_POSTS_FILTERED_QUERY = gql`
  query GetBlogPostsFiltered($id: ID!, $limit: Int!) {
    blogPosts(filters: { id: { ne: $id } }, pagination: { limit: $limit }) {
      data {
        id
        attributes {
          titulo
          imagem {
            data {
              attributes {
                url
              }
            }
          }
          conteudo
        }
      }
    }
  }
`;

export const GET_IMPRENSA_BANNERS_QUERY = gql`
  query GetConteudosImprensa {
    conteudoImprensas(pagination: { limit: -1 }, sort: "createdAt:desc") {
      data {
        id
        attributes {
          titulo
          createdAt
          imagem {
            data {
              attributes {
                url
              }
            }
          }
          link
        }
      }
    }
  }
`;
