import React, { useEffect } from 'react'
import './Newsletter.css'
import { Formik, Field } from 'formik';

//Dependencias Internas
import { useMutation } from '@apollo/client';
import { ADD_NEWSLETTER_EMAIL_MUTATION } from '../../../services/graphql';

const errorMap = {
    'This attribute must be unique': 'Este e-mail já está cadastrado!',
    'Default': 'Ops! Algo de errado aconteceu.'
}

const mapError = (error) => {
    return errorMap[error.message] || errorMap['Default']
}

export default props => {
    const [addNewsletterEmail, { data, error }] = useMutation(ADD_NEWSLETTER_EMAIL_MUTATION);

    useEffect(() => {
        console.log({ data, error });
    }, [data, error])

    return (
        < section className="newsletter" >
            <h2 className="title-section">
                Quer saber dos<br />
            </h2>
            <h2 className="title-section">
                lançamentos antes de todos?<br />
            Se inscreva na nossa newsletter!
        </h2>
            <div className="container">
                <div className="row form-newsletter">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 ">
                        <Formik
                            initialValues={{ email: '' }}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);
                                addNewsletterEmail({ variables: { email: values.email } })
                                    .then(response => {
                                        setSubmitting(false)
                                        document.getElementById("newsletter-form").reset();
                                    })
                                    .catch(err => {
                                        setSubmitting(false)
                                    })
                            }}
                        >
                            {({
                                handleSubmit,
                                isSubmitting
                                /* and other goodies */
                            }) => (
                                    <form onSubmit={handleSubmit} id="newsletter-form">
                                        <div className="input-group input-group-lg mb-3">
                                            <Field type="email" className="form-control" name="email" placeholder="Seu email" aria-label="Seu email" aria-describedby="button-addon2" />

                                            <div className="input-group-append">
                                                <button className="btn btn-black" type="submit" disabled={isSubmitting}>Enviar</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                        </Formik>
                        {data &&
                            <p className="font-weight-bold">Obrigado por se inscrever em nossa newsletter!</p>
                        }
                        {error &&
                            <p className="font-weight-bold">{mapError(error)}</p>
                        }
                    </div>
                </div>
            </div>
        </section >
    )
}