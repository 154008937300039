import React from "react";
import "./Blog.css";

//Dependencias Internas
import { useQuery } from "@apollo/client";
import { BlogItem } from "./BlogItem";
import { GET_BLOG_POSTS_QUERY } from "../../../services/graphql";

export default (props) => {
  const { data } = useQuery(GET_BLOG_POSTS_QUERY, { variables: { limit: 3 } });

  return (
    <section className="blog" id="blog">
      <div className="container-custom">
        <h2 className="title-section">Nosso Blog</h2>
        <div className="row">
            {data?.blogPosts?.data.map((el) => (
                <BlogItem key={el.id} item={el} />
            ))}
        </div>
      </div>
    </section>
  );
};
