import axios from "axios"
import { getToken, logoutFunc } from './storageHandler'

const URL = process.env.REACT_APP_URL || 'https://api.leoacrilicos.com.br/'

const api = axios.create({
    baseURL: URL
});

api.interceptors.request.use(async config => {
    var token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(response => {
    return response;
}, function (error) {
    if (error.response.data) {
        var response = error.response.data
        if (response.statusCode === 401) {
            if (response.message === 'Unauthorized') {
                window.location.href = '/'
                logoutFunc()
            }
        }
    }
    return Promise.reject(error);
});

export default api;