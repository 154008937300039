import React from 'react'
import './WelcomeTextos.css'

//Dependencias Internas
import Modernidade from '../../../assets/images/ideia.svg'
import Originalidade from '../../../assets/images/certificado.svg'
import Versatilidade from '../../../assets/images/tela-cheia.svg'
import Qualidade from '../../../assets/images/escudo.svg'

export default props =>
    <section className="welcome-textos">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3 texto">
                    <div className="texto-icon">
                        <img src={Modernidade} alt="icone mordenidade" />
                    </div>
                    <h5>Modernidade</h5>
                    <p>Muito mais que decorar ambientes, nosso intuito é trazer modernidade, compondo a
                    personalidade de cada cliente. Para isso, buscamos sempre estar de acordo com as
                    tendências do Brasil e do mundo, possibilitando um design sofisticado e único para
                que nossos clientes consigam inovar qualquer ambiente. Não temos limite para inovar!</p>
                </div>
                <div className="col-md-3 texto">
                    <div className="texto-icon">
                        <img src={Originalidade} alt="icone originalidade" />
                    </div>
                    <h5>Originalidade</h5>
                    <p>Aceitamos projetos exclusivos e originais, que venham de ideias de nossos clientes, e
                    com isso produzimos peças sob medida, muitas vezes únicas, que carregam o gosto, a
                    cultura e a personalidade de cada um. Tudo isso, em um só móvel de acrílico. Você
                pode fazer o que quiser, use a criatividade e nós desenvolvemos o produto!</p>
                </div>
                <div className="col-md-3 texto">
                    <div className="texto-icon">
                        <img src={Versatilidade} alt="icone versatilidade" />
                    </div>
                    <h5>Versatilidade</h5>
                    <p>Os móveis de acrílicos possuem um potente diferencial perante a outras matérias-
                    primas. Eles são versáteis. Você consegue inseri-los em qualquer ambiente,
                    construídos com diversas decorações e identidades, além de poder misturar o acrílico
                    com outros materiais que irão continuar passando requinte e sofisticação. Se você
                cansar, o móvel pode ser inserido em outro ambiente!</p>
                </div>
                <div className="col-md-3 texto">
                    <div className="texto-icon">
                        <img src={Qualidade} alt="icone qualidade" />
                    </div>
                    <h5>Qualidade</h5>
                    <p>Os móveis da Léo Acrílicos são elaborados a mão, com matérias-primas vindas de
                    fornecedores consagrados, possuem alta durabilidade e resistência para qualquer tipo
                de ambiente e de clima. Nossa qualidade é comprovada!</p>
                </div>

            </div>
        </div>

    </section>
