import React from "react";
import "./Navbar.css";

//Dependencias Internas
import LogoHeader from "../../../assets/images/logo_header.png";
import { useQuery } from "@apollo/client";
import { GET_CATALOGO_QUERY } from "../../../services/graphql";

export default (props) => {
  const { data } = useQuery(GET_CATALOGO_QUERY);

  return (
    <>
      <nav className='main-navbar navbar navbar-expand-lg navbar-light bg-light'>
        <a className='navbar-brand' href='/'>
          <img src={LogoHeader} alt='Logo Léo Acrilicos Header' />
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarNavAltMarkup'
          aria-controls='navbarNavAltMarkup'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarNavAltMarkup'>
          <ul className='navbar-nav ml-auto mr-md-3'>
            <li className='nav-item '>
              <a href='/home' className='nav-link'>
                Home <span className='sr-only'>(current)</span>
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='/sobre-nos'>
                Sobre nós
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-item nav-link' href='/home#Imprensa'>
                Imprensa
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-item nav-link' href='/home#blog'>
                Blog
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-item nav-link' href='/home#footer'>
                Contato
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-item nav-link'
                href='https://shop.leoacrilicos.com.br'
              >
                Loja
              </a>
            </li>
            <li className='nav-item'>
              {data && (
                <a
                  className='nav-item nav-link row'
                  href={data.catalogo.data.attributes.arquivo.data.attributes.url}
                  rel='noopener noreferrer'
                  target='_blank'
                  download
                >
                  <div className='baixar-catalogo'>
                    <i className='fas fa-book-open' title='Baixar Catálogo'>
                      {" "}
                    </i>
                    Catálogo
                  </div>
                </a>
              )}
            </li>
          </ul>
          {/*  <div className='navbar-nav ml-auto '>
              <a className='nav-item nav-link' href='/home'>
                Home <span className='sr-only'>(current)</span>
              </a>
              <a className='nav-item nav-link' href='/sobre-nos'>
                Sobre nós
              </a>
              <a className='nav-item nav-link' href='/home#blog'>
                Blog
              </a>
              <a className='nav-item nav-link' href='/home#footer'>
                Contato
              </a>
              <a
                className='nav-item nav-link'
                href='https://shop.leoacrilicos.com.br'
              >
                Loja
              </a>
              {catalogo && (
                <a
                  className='nav-item nav-link row'
                  href={catalogo.catalogo_url}
                  target='_blank'
                  download
                >
                  <div className='baixar-catalogo'>
                    <i className='fas fa-book-open' title='Baixar Catálogo'>
                      {" "}
                    </i>
                    Catálogo
                  </div>
                </a>
              )}
            </div> */}
        </div>
      </nav>
    </>
  );
};
