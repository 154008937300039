import React from 'react'
import './Personalizados.css'


export default props =>

    <section className="produtos-personalizados">
        <div className="title-section">
            <h2>Produtos personalizados <br /> feitos sob medida</h2>
        </div>
        <button className="btn btn-outline">
            <a href="/produto-personalizado">
                <div>
                    FAÇA UM ORÇAMENTO
            </div>
            </a>
        </button>
    </section>
