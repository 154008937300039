import React from 'react'
import './Card.css'

import Img from "react-cool-img"

export default props => {
    return (
        <div className="card" >
            {props.produto ?
                <>
                    <Img
                        className="card-img-top"
                        placeholder="Carregando"
                        src={props.produto.images[0].src}
                        error={"Erro"}
                        cache
                        alt={props.produto.name.pt}
                    />
                    <div className="card-body">
                        <h5 className="card-title">{props.produto.name.pt}</h5>
                        <div className="card-text">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="produto-valor">
                                        {props.produto.variants[0].promotional_price &&
                                            <span className="valor-antigo">
                                                <span className="outer">
                                                    <span className="inner">R$ {props.produto.variants[0].price}</span>
                                                </span>
                                            </span>
                                        }
                                        {props.produto.variants[0].promotional_price ?
                                            <span className="valor-novo"> R$ {props.produto.variants[0].promotional_price}</span>
                                            :
                                            <span className="valor-novo"> R$ {props.produto.variants[0].price}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-muted">
                        <div className="row">
                            <div className="col-md-12">
                                <a href={props.produto.canonical_url}>
                                    <button className="btn btn-black">
                                        Comprar
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="carregando-img">
                        <i class="fas fa-spinner fa-pulse"></i>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">
                            <div className="carregando-title">
                                <p style={{ width: '90%' }}></p>
                                <p style={{ width: '45%' }}></p>
                                <p style={{ width: '20%' }}></p>
                            </div>
                        </h5>
                        <div className="card-text">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="produto-valor">
                                        <span className="valor-novo"> <div className="carregando-valor"></div></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-muted">
                        <div className="row">
                            <div className="col-md-12">
                                <button className="btn btn-black">
                                    Comprar
                                </button>
                            </div>
                        </div>
                    </div>
                </>}
        </div >

    )
}