import React from 'react'
import './Depoimentos.css'

//Dependencias Internas
import Cliente1 from '../../../assets/images/depoimentos/Cliente1.png'

export default props =>
    <section className="depoimentos">
        <h2 className="title-section"> Ouça de quem já comprou</h2>
        <div className="depoimento-content">
            <img src={Cliente1} className="img-depoimento" alt="person" />
            <p className="texto-depoimento">"Meu primeiro produto da Léo Acrílicos, foi uma mesa de centro. Até hoje, ela é motivo de elogios na sala da minha casa. E eu já aproveitei ela em 02 ambientes diferentes, e ambos ficaram lindos. Minha mesa continua com a mesma qualidade, mesmo depois de 04 anos. Levei a Léo Acrílicos para meu ambiente de trabalho, em carrinhos e organizadores. Também estou amando e pretendo adquiria mais um!"</p>
            <p className="cliente-depoimento">Terezinha Vigolo</p>
            <p className="cargo-depoimento">@terezinhavigolo</p>
        </div>
    </section>