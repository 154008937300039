import React, { useEffect, useState } from 'react'
import './MaisVendidos.css'

//Dependencias Internas
import Card from './card/Card'
import api from '../../../services/Api'

export default props => {
    const [produtos, setProdutos] = useState([])

    useEffect(() => {
        api.get("/produtos")
            .then((response) => {
                setProdutos(response.data)
            })
            .catch(err => console.log(JSON.stringify(err)));

    }, [])

    return (
        <section className="mais-vendidos">
            <div className="container-custom">
                <h2 className="title-section">Os mais vendidos</h2>
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-xl-3">
                        <Card produto={produtos[0]} />
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3">
                        <Card produto={produtos[1]} />
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3">
                        <Card produto={produtos[2]} />
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3">
                        <Card produto={produtos[3]} />
                    </div>
                </div>

            </div>
        </section>
    )
}