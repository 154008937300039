import React from "react";
import ReactDOM from "react-dom";
import Routes from "./Routes";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";

const URL =
  process.env.REACT_APP_URL_GRAPHQL ||
  "https://painel.leoacrilicos.com.br/graphql";

const client = new ApolloClient({
  uri: URL,
  cache: new InMemoryCache(),
});


ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Routes />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
