import React from "react";

export function ImprensaItem({ item }) {
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 blog-content">
      <a href={item.attributes.link} target="_blank" rel="noopener noreferrer">
        <picture>
          <img
            src={item.attributes.imagem.data.attributes.url}
            alt="Imagem preview da Matéria"
          />
        </picture>
        <p className="blog-desc">{item.attributes.titulo}</p>
      </a>
    </div>
  );
}
