import React from 'react'
import './Banner.css'

//Dependencias Internas
import LogoBanner from '../../../assets/images/logoverticalbco.png'

//Dependencias Externas
import Img from "react-cool-img"
import { useQuery } from '@apollo/client'
import { GET_BANNERS_QUERY } from '../../../services/graphql'

export default props => {
    const { data } = useQuery(GET_BANNERS_QUERY);

    console.log(data);


    return (
        <section className="banner-home">
            <div id="carrouselBanners" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="text-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-banner">
                                    <div className="vertical-logo">
                                        <img src={LogoBanner} alt="Logo Leo Acrilicos Banner" />
                                    </div>
                                    <h1 className="text-banner">peças exclusivas</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        data?.banners?.data.map((el, index) =>
                            <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index} >
                                {/* <img src={el.image_url} className="img-fluid" alt="..." /> */}
                                <Img
                                    className="img-fluid"
                                    src={el.attributes.imagem.data.attributes.url}
                                    error={"Erro"}
                                    cache
                                    alt="Banner Léo Acrilicos"
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        </section >
    )
}