import React from "react";
import "../blog/Blog.css";

//Dependencias Internas
import { useQuery } from "@apollo/client";
import { ImprensaItem } from "./ImprensaItem";
import { GET_IMPRENSA_BANNERS_QUERY } from "../../../services/graphql";

export default (props) => {
  const { data } = useQuery(GET_IMPRENSA_BANNERS_QUERY);

  return (
    <section className="blog" id="Imprensa">
      <div className="container-custom">
        <h2 className="title-section">Imprensa</h2>
        <div className="row">
          {data?.conteudoImprensas.data?.map((el) => (
            <ImprensaItem item={el} />
          ))}
        </div>
      </div>
    </section>
  );
};
