import React from 'react'
import './Diferenciais.css'

//Dependencias Internas
import icon10 from '../../../assets/images/20_anos.png'
import icon10_WEBP from '../../../assets/images/20_anos.webp'
import iconEntrega from '../../../assets/images/entrega_icon.png'
import iconEntrega_WEBP from '../../../assets/images/entrega_icon.webp'
import iconQualidade from '../../../assets/images/qualidade_icon.png'
import iconQualidade_WEBP from '../../../assets/images/qualidade_icon.webp'

export default props =>
    <section className="diferenciais">
        <h2 className="title-section">Acrilíco é a nossa paixão</h2>
        <div className="container-custom">
            <div className="row">
                <div className="col-md-12 col-lg-4 col-xl-4 diferencial">
                    <picture>
                        <source srcset={icon10_WEBP} type="image/webp" />
                        <img src={icon10} alt="Icone 10 anos de experiencia" />
                    </picture>
                    <p className="diferencial-title">anos de mercado</p>
                    <p className="diferencial-desc">Somos consolidados no mercado de móveis de luxo, com mais de 20 anos de experiência.</p>
                </div>
                <div className="col-md-12 col-lg-4 col-xl-4 diferencial">
                    <picture>
                        <source srcset={iconEntrega_WEBP} type="image/webp" />
                        <img src={iconEntrega} alt="Icone entrega segura" />
                    </picture>
                    <p className="diferencial-title">entrega segura</p>
                    <p className="diferencial-desc">Trabalhamos com transportadoras seguras e acostumadas a transportar nossos materiais a mais de 10 anos, respeitando os prazos de entrega.</p>
                </div>
                <div className="col-md-12 col-lg-4 col-xl-4 diferencial">
                    <picture>
                        <source srcset={iconQualidade_WEBP} type="image/webp" />
                        <img src={iconQualidade} alt="Icone qualidade acima de tudo" />
                    </picture>
                    <p className="diferencial-title">qualidade acima de tudo</p>
                    <p className="diferencial-desc">Somos focados em oferecer qualidade superior em todos os nossos produtos, trabalhando com fornecedores consagrados e garantindo o melhor tratamento do produto.</p>
                </div>
            </div>
        </div>
    </section>