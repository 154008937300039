
/* GETTERS */
export const isAuthenticated = () => localStorage.getItem('token') !== null;
export const getToken = () => localStorage.getItem('token');
export const getUsername = () => localStorage.getItem('username');

/* SETTERS */
export const loginFunc = (token, username) => {
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
};

export const logoutFunc = (props) => {
    localStorage.removeItem('token');
    window.location.href = '/painel';
};
